.action-btn {
  width: 100%;
  border-radius: 10px;
  height: 48px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  min-height: 40px;
}

.action-btn_style_black {
  background: #333333;
  color: white;
  height: 50px;
}

.action-btn_style_disabled {
  background: #4F4F4F;
  color: #828282;
}

.action-btn_style_active {
  background: #FFFFFF;
  color: #333333;
}

.action-btn_style_white {
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0 1px 4px #828282;
  color: #333333;
}