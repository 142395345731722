@import "../../variables";

.card-list {
  width: 100%;
}

.card-list__item {
  margin-top: 20px;
  width: 100%;
  height: 54px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #828282;
  border-radius: 10px;
}

.card-list__info {
}

.card-name {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
}

.card-number {
  color: #828282;
}