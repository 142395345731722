@import "../../variables";

.short-info {
  background: #4F4F4F;
  width: 100%;
  height: 180px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.admin__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  background: #4F4F4F;
  width: 116px;
  height: 116px;
  border-radius: 50%;
}

.short-info__name {
  font-size: 16px;
  font-weight: 600;
}

.short-info__balance {
  //margin-top: 25px;
}

.short-info__balance-value {
  display: flex;
  justify-content: center;
}

.admin__sum {
  margin-top: 54px;
}

.admin__fee {
  font-size: 13px;
  margin-top: 8px;
  margin-left: 50px;
}

.admin__add-card {
  margin-top: 20px;
  width: 100%;
}

.admin__picked-card {
  margin-top: 25px;
}

.admin__percent-info {
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

.money__withdraw-btn {
  margin-top: 30px
}

.employee-edit__external {
  margin-top: 30px;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.employee-edit__external_text {
  background: black;
  color: white;
}