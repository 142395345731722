.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .avatar-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .avatar-small {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .avatar-img-small {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }