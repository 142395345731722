.company__list {
  margin-top: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.company__item {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company__avatar {
  height: 40px;
  width: 40px;
}

.company__name {
  font-weight: 600;
  font-size: 16px;
}

.company__search {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

.search-input {
  left: 0;
  position: absolute;
  padding: 1px;
  font-size: 16px;
  padding-right: 0;
  padding-left: 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 100%;
}