/* The switch - the box around the slider */
.switcher {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 28px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switcher input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switcher__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switcher__slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 1px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switcher input:checked + .switcher__slider {
  background-color: #F2C94C;
}

.switcher input:focus + .switcher__slider {
  box-shadow: 0 0 1px #F2C94C;
}

.switcher input:checked + .switcher__slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switcher__slider.switcher__slider_round {
  border-radius: 34px;
}

.switcher__slider.switcher__slider_round:before {
  border-radius: 50%;
}