.employee-add__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 40px;
  margin-left: 20px;
}

.employee__phone {
  margin-top: 20px;
}