@import "../../../variables";

.registration {
  background: $main-color;
}

.registration__app-container {
  padding: 50px 40px;
  justify-content: space-between;
}

.registration__header {
  width: 100%;
  display: flex;
}

.registration__inputs {
  margin-top: 100px;
}

.registration__input {
  margin-top: 42px;
}

.registration__controls {
  margin-top: 80px;
}