.lined-input {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
}

.lined-input__img {
  margin-right: 26px;
}

.lined-input__text {
  display: flex;
  flex: 1;
  position: relative;
  border-bottom: 2px solid #828282;

  .floating-label {
    font-size: 16px;
    color: #828282;
    position: absolute;
    pointer-events: none;
    //left: 12px;
    transition: all 0.1s ease;
  }

  .static-label {
    font-size: 16px;
    color: #828282;
    position: absolute;
    pointer-events: none;
  }

  input:focus ~ .floating-label,
  input:not(:focus):valid ~ .floating-label, {
    color: #828282;
    top: -15px;
    bottom: 0;
    left: 0;
    font-size: 11px;
    opacity: 1;
  }

  input:focus ~ .static-label,
  input:not(:focus):valid ~ .static-label {
    opacity: 0;
  }
}
.floating-input:-webkit-autofill,
.floating-input:-webkit-autofill:hover,
.floating-input:-webkit-autofill:focus{
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

.floating-input {
  width: 100%;
  line-height: 20px;
  height: 20px;
  padding: 0 5px;
  background: none;
  font-weight: 500;
  border: none;
}

.floating-input_theme_dark {
  color: #FFFFFF;
  font-size: 16px;
}

.floating-input_theme_white {
  color: #333333;
  font-size: 14px;
}

.floating-input:focus {
  outline: none;
}
