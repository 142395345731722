.img-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.img-item__text {
  margin-left: 16px;
  display: flex;
  flex: 1;
}

.img-item__avatar {
  width: 40px;
  height: 40px;
}