.notification {
  background: #333333;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 10px;
}

.notification__info {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.notification__title {
  display: flex;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}

.notification__description {
  line-height: 1.3;
  font-size: 14px;
  font-weight: 400;
  color: #8C8A9B;
}

.notification__theme_info {
  color: #FFFFFF;
}

.notification__theme_success {
  color: #219653;
}

.notification__theme_warning {
  color: #F2C94C;
}

.notification__theme_error {
  color: #EB5757;
}

.notification__badge {
  margin-right: 24px;
  //width: 36px;
  //height: 36px;
}

