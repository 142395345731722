@import "../../variables";

.menu {
    background: $main-color;
    width: 100%;
}

.menu__header {
    display: flex;
}

.menu__item {
    display: flex;
    margin-top: 42px;
}

.menu__item-text {
    margin-left: 26px;
}

.menu_qr {
    background: white;
    width: 110px;
    height: 110px;
    display: flex;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
}

.menu__footer {
    display: flex;
    margin-top: 65px;
}

.locales {
    display: flex;
    flex-direction: row;
}