
.success-result {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success-result__text {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}