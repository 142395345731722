@import "../../variables";

.tips {
  background: #f2f2f2;
  color: black;
}

.tips__app-container {
  padding-top: 20px;
}

.tips__service-header {
  display: flex;
  justify-content: center;
}

.tips__employee {
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tips__employee-image {
  width: 84px;
  height: 84px;
  border-radius: 50%;
}

.bill {
  margin-top: 30px;
  padding: 0 16px;
}

.bill__avatar{
  position: relative;
}

.avatar__info {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 20px;
}

.avatar__user {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.avatar__company {
  font-size: 14px;
  color: black;
}

.bill__avatar-image {
  top: -50px;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  border: 7px solid white;
  position: absolute;
}

.bill__panel {
  background: #ffffff;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  border-radius: 30px 30px 10px 10px;
}

.bill__data {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bill__item {
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bill__item_theme_summary {
  padding-top: 10px;
  border-top: 1px solid #BDBDBD;
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.bill__text {
  display: flex;
}

.bill__switcher {
  margin-top: 15px;
  padding: 0 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tips__employee-info {
  max-width: 90vw;
  line-height: 1.25;
  display: flex;
  flex-direction: column;

  .top-row {
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: center;

    .avatar-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100px;
      height: 70px;
    }

    .text-container {
      max-width: calc(80vw-100px);

      .tips__employee-name {
        font-size: 130%;
        font-weight: 600;
        color: #333333;
      }

      .tips__company-name {
        font-size: 90%;
        font-weight: 500;
        color: #333333;
      }
    }
  }
}

.tips__employee-subscription {
  margin-top: 10px;
  max-width: 200px;
  font-size: 15px;
  color: #828282;
}

.pay-block {
  background: #ffffff;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
  padding: 16px 20px 0;
  border-radius: 20px 20px 0 0;
}

.tips__panel {
  margin-bottom: 20px;
}

.amount__caption {
  margin-bottom: 8px;
}

.small-caption {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.amount__value {
  width: 100%;
  border-bottom: 2px solid #828282;
}

.amount__input {
  width: 100%;
  line-height: 40px;
  height: 40px;
  padding: 0 5px;
  color: #333333;
  background: none;
  font-weight: 700;
  font-size: 32px;
  border: none;
  text-align: center;
  -webkit-appearance: none;
}
.amount__input:focus {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.quick-amount {
  display: flex;
  overflow-x: auto;
  width: auto;
  margin-top: 20px;
  justify-content: space-between;
  cursor: pointer;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.quick-amount::-webkit-scrollbar {
  display: none;
}

.quick-amount__item {
  display: flex;
  background: #ffffff;
  padding: 10px 16px;
  margin-right: 8px;
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #333333;
}

.bill-quick-amount__item {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: 12px;
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  padding: 3px 0;
  min-width: 90px;
  border-radius: 10px;
}

.quick-amount__item_theme_active {
  background: #f2c94c;
  border: none;
}

.another-tip-sum {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.another-tip-sum__actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.another-tip-sum__btn {
  background: #FFFFFF;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.another-tip-sum__amount {
  background: #FFFFFF;
  margin: 0 7px;
  padding: 10px 30px 5px;
  height: 56px;
  display: flex;
  flex: 1;
  border-radius: 10px;
}

.another-tip-sum__input {
  border: none;
  border-bottom: 2px black;
}

.another-tip-sum__small-caption {
  margin-top: 10px;
}

.tips__estimate {
  margin-top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333333;
  font-weight: 600;

  .fast_comments_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    .fast_comments {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 15px;
    }
  }
}

.estimate__starts {
  margin-top: 15px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.estimate__star {
}

.estimate__comment {
  margin-top: 35px;
}

.tips__agreement {
  display: flex;
  flex: 1;
  max-height: 50px;
}

.tips__actions {
  margin-bottom: 70px;
}

.tips__action-item {
  margin-top: 20px;
}

.tips__header {
  display: flex;
  justify-content: space-between;
}

.tips__center {
  display: flex;
  flex-direction: column;
  flex: 1;
}

// card

.card-form {
  width: 100%;
  color: black;
}

.card-form__tips {
  padding: 50px 20px;
}

.card-form__title {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
}

.card-form__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.card-form__data {
  background: rgba(204, 207, 213, 0.43);
  margin-top: 30px;
  padding: 0 5px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.tips-modal {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tips-modal__overlay {
  background: #f2f2f2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  position: fixed;
  z-index: 1;
}

.tips__bubble {
  display: flex;
  flex: 1;
}

.success-sum {
  display: flex;
  align-items: center;
}

.success-adv {
  margin-top: 20px;
}

.tips-footer {
  width: 100%;
  left: 0;
  display: flex;

  .tips-footer__contacts {
    display: block;
    margin: 0 auto;
    color: black;
    text-align: center;
  }
}
