.img-button {
  background: rgba(189, 189, 189, 0.3);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-radius: 10px;
  cursor: pointer;
}

.img-button__text {
  font-weight: 600;
  display: flex;
  flex: 1;
  margin-left: 10px;
}