@import "../../variables";

.contacts {
  display: flex;
  flex-grow: 1;
  padding: 50px 20px;
  width: 100%;
  flex-direction: column;
}

.light {
  background-color: #f2f2f2;
  color: #333333;
}