$main-color: #1A0E03;

.central {
  height: 100vh;
  display: flex;
  justify-content: center;
  //border: 1px dashed yellow;
}

.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 415px;
  overflow: scroll;
}

.gray-text {
  color: #828282;
}

.green-text {
  color: #219653;
}

.red-text {
  color: red;
}

.big-font {
  font-size: 32px;
  font-weight: 700;
}

.middle-font {
  font-size: 22px;
  font-weight: 500;
}

.small-font {
  font-size: 16px;
}

.header-text {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 20px;
  font-weight: 600;
}

.cross-text {
  text-decoration: line-through;
}

.success {
  color: #219653;
}

.squared-checkbox {
  border: 2px solid #828282;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  flex-shrink: 0;
}

.squared-checkbox_checked {
  background: white;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input__item {
  margin-top: 42px;
}

.no-background {
  color: #FFFFFF;
  width: 100%;
  background: none;
  border: none;
}

.no-background:focus {
  outline: none;
}

.img-circle {
  border-radius: 50%;
}

.ReactModal__Body--open {
  overflow: hidden;
}