@import "../../variables";

.admin {
  background: $main-color;
  width: 100%;
}

.admin__app-container {
  padding: 50px 40px
}

.admin__header {
  display: flex;
  justify-content: space-between;
}

.admin__center {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.circle-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle-success__text {
  line-height: 1;
}

.admin__transaction-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
  padding-bottom: 40px;
}

.admin__transaction-list::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.transaction-text {
  display: flex;
  font-size: 13px;
}

.transaction-list__item {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  border-bottom: 2px solid #828282;
}

.transaction-type {
  font-size: 13px;
}

.transaction-table {
  font-size: 13px;
  margin-left: 20px;
}

.transaction-list__date-line {
  margin: 25px 0;
  margin-left: 14px;
  color: #828282;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}

.transaction-list__estimate {
  margin-left: 15px;
  height: 20px;
}

.transaction-amount {
  font-size: 20px;
  font-weight: 600;
}


.admin__footer {
  display: flex;
  margin-top: 25px;
}

// ---------- modals

.modal-padding {
  //margin-top: 48px;
  width: 100%;
}

.admin-modal {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.admin-modal__overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  position: fixed;
  z-index: 1;
}

.left-slide-window .ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 300ms ease-in-out;
}

.left-slide-window .ReactModal__Overlay--after-open {
  //background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transform: translateX(0px);
}

.left-slide-window .ReactModal__Overlay--before-close {
  background: none;
  opacity: 0;
  transform: translateX(-100px);
}

.admin__popup {
  margin-top: 40px;

  p {
    cursor: pointer;

    i {
      text-decoration: underline;
      font-style: normal;
    }
  }

  ul {
    text-decoration: none;

    li {
      list-style: none;
      cursor: pointer;
      padding: 10px;

      &:hover {
        color: #0056b3;
        text-decoration: underline;
      }
    }
  }
}