.employee-list__items {
  margin-top: 25px;
  width: 100%;
}

.employee-list__item {
  margin-top: 15px;
}

.employee-list__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.employee-list__users-empty-img {
}

.employee-list__users-empty-text {
  margin-top: 16px;
  color: #828282;
  text-align: center;
}