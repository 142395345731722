.square-btn {
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 5px 7px;
  width: 47px;
  height: 48px;
  cursor: pointer;
}

.square-btn_active {
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(25, 17, 126, 0.08);
  border-radius: 10px;
}

.square-btn_disabled {
  background: #EDEFF4;
  box-shadow: 0px 4px 25px rgba(25, 17, 126, 0.08);
  border-radius: 10px;
}