@import "../../variables";

.profile__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.profile__avatar-edit {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 600;
}

.profile__actions {
  margin-top: 25px;
}

.profile__action-item {
  margin-top: 15px;
}

.profile__crop {
  margin-top: 40px;
  margin-bottom: 20px;
}