.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 31%;

  .text {
    width: 100%;
    font-size: 80%;
    font-weight: 600;
    margin-top: 3px;
    color: #333333;
  }
}
