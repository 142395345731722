.company-profile {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-profile__avatar {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
}

.company-profile__name {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 600;
}

.company-profile__info-item {
  margin-top: 15px;
  display: flex;
  width: 100%;
}

.company-profile__actions {
  margin-top: 25px;
  width: 100%;
}

.company-profile__action-item {
  margin-top: 16px;
}

.company-transactions {
  margin-top: 30px;
  width: 100%;
}

.company-transactions__switcher {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.transaction__switch-button {
  display: flex;
  width: 100px;
  height: 30px;
  background: rgba(204, 207, 213, 0.5);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.transaction__switch-button_disabled {
  background: rgba(204, 207, 213, 0.2);
  color: #828282;
}

.company-transactions__user-name {
  font-size: 14px;
}

.list-full {
  font-size: 14px;
  color: gray;
  width: 100%;
  text-align: center;
}