$gray: #828282;
$green: #219653;

.common-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  margin-top: 15px;
  min-height: 40px;
  border-bottom: 2px solid $gray;

  .date-row {
    @extend .common-style;
    
    margin: 25px 0;
    color: $gray;
    font-size: 13px;
  }

  .row {
    @extend .common-style;
    justify-content: space-between;

    .date-tr-type {
      @extend .common-style;

      .date {
        width: 50px;
      }

      .transaction-type {
        font-size: 13px;
        width: 55px;
        text-align: center;
      }
    }

    .table-amount {
      @extend .common-style;

      .transaction-table {
        font-size: 13px;
        margin-right: 10px;
      }
    }

    .quick-comments {
      @extend .common-style;

      color: #f2c94c;

      .quick-comment {
        width: 30px;
        height: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .comment {
      font-size: 13px;
    }

    .green-text {
      color: $green;
    }

    .gray-text {
      color: $gray;
    }
  }
}
