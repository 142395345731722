@import "../../variables";

.auth {
  background: $main-color;
}

.auth__app-container {
  padding: 20px 40px;
}

.auth__logo {
  margin-top: 30px;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo__image {
  display: flex;
  justify-content: center;
}

.logo__text {
  margin-top: 16px;
  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
}

.auth__content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-around;
}

.auth__inputs {
  width: 100%;
}

.input-block__item:first-child {
  margin-top: 0;
}

.input-block__item {
  margin-top: 42px;
  display: flex;
  justify-content: center;
}

.input-block__input {
  background: none;
  width: 100%;
  border: none;
  line-height: 50px;
}

.input-block__input:focus {
  background: none;
  outline: none;
  border-bottom: 2px solid #4980F9;
  border-radius: unset;
}

.auth__buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.control__button {
  width: 100%;
  border-radius: 10px;
  height: 40px;
  border: none;
  font-size: 16px;
  font-weight: 600;
}

.control__button_style_inactive {
  background: #4F4F4F;
  color: #828282;
}

.control__button_style_active {
  background: #FFFFFF;
  color: #333333;
}

.control__button:focus {
  outline: none;
}

.auth__registration {
  margin-top: 30px;
  font-size: 15px;
}

.auth__registration_link {
  color: white;
  align-content: center;
}

.footer__text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: rgba(255, 255, 255, 0.44);
}

.footer {
  flex: 0 0 auto;
}